<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Awaiting Approval</h2>
        <router-link
          :to="{ name: 'new-merchant' }"
          class="text-sm text-gray-700"
          >+ Add new merchant</router-link
        >
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search for merchant's name, industry, status, etc."
          class="mb-5"
          v-model="merchants.searchQuery"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="merchants.url"
            :ajax="true"
            :ajax-pagination="true"
            :columns="merchants.columns"
            :query="merchants.searchQuery"
            :fillable="false"
            :index="true"
            :page-details="true"
            :on-click="click"
            ref="table"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      merchants: this.$options.resource([], {
        searchQuery: '',
        url: `${this.$baseurl}/admin/merchants/pending`,
        columns: [
          {
            name: 'name',
            th: 'Name',
            render: merchant => `${merchant?.first_name} ${merchant?.last_name}`
          },
          {
            name: 'business_name',
            th: 'Business Name'
          },
          {
            name: 'industry',
            th: 'Industry',
            render: merchant => merchant?.industry?.name || ''
          },
          {
            name: 'email',
            th: 'email'
          },
          {
            name: 'state',
            th: 'State',
            render: merchant => merchant?.descriptions?.state
          },
          {
            name: 'source',
            th: 'Source',
            render: ({ source }) => {
              const { sentenceCase } = this.$options.filters;
              return sentenceCase(source);
            }
          },
          {
            name: 'status',
            th: 'Status',
            render: (merchant, status) => {
              switch (status) {
                case 'approved':
                  return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                    Approved
                  </div>`;
                case 'suspended':
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Suspended
                  </div>`;
                case 'rejected':
                  return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
                    Rejected
                  </div>`;
                case 'pending':
                default:
                  return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Pending
                  </div>`;
              }
            }
          },
          {
            name: 'created_at',
            th: 'Date Registered',
            render: (merchant, created_at) =>
              this.$moment(created_at).format('MMM Do YYYY')
          }
        ]
      }),
      isOpen: false
    };
  },
  methods: {
    click(selected) {
      this.$router.push({
        name: 'merchant-profile',
        params: {
          id: selected.id
        }
      });
    }
  }
};
</script>
